import React, { useState, useEffect, useRef } from "react";
import { CssObjetivos } from "./cssObjetivos";

import Logo from "@/assets/imagens/logo-zest-branco.svg"
import Mulher from "@/assets/imagens/mulher2.svg"

const Objetivos = () => { 
 
  const [showObj, setShowObj] = useState(false);
  const confiaObj = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const mostrar = document.getElementById("objetivo");
      console.log("🚀 ~ handleScroll ~ objetivo:", mostrar)
  
      if (!mostrar) return;
  
      const rectF = mostrar.getBoundingClientRect();
      const faixaDE = rectF.top;
  
      if (faixaDE <= window.innerHeight - 190) {
        setShowObj(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CssObjetivos>  


      <div id="obj" className={showObj ? "textoobj" : ""}>
        Porque trabalhar com a zest?
      </div>

      <div ref={confiaObj} id="objetivo" className={showObj ? "aparecerObj" : ""}>
        Traduzimos os objetivos <span/> do negócio e a estragtégia <span/> da marca em ações concretas <span/> e impactantes.
      </div>

      <div id="mulherObj" className={showObj ? 'apareceMulherObj' : ''}>
        <img src={Mulher} alt="Mulher sorrindo" className="img"/>
      </div> 
      
      <div id="faixa2Obj" className={showObj ? 'visivelFaixa2Resul' : ''}></div>

      <div id="loguinhoObj" className={showObj ? "mostraLogoObj" : ""}>
        <img src={Logo} alt="ZEST1" className="img" />
      </div> 
    </CssObjetivos>
  );
  
}

export default Objetivos;
